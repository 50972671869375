/** 全局异常捕捉 */
import { send_catch_err } from '@/utils/rocketChat'

export function initCatchError(app) {
  initWindowError()
  initVueCatchError(app)
}

function initWindowError() {
  window.onerror = async function (message, source, lineno, colno, error) {
    // console.log('错误信息: ' + message)
    // console.log('出错文件: ' + source)
    // console.log('出错行号: ' + lineno)
    // console.log('出错列号: ' + colno)
    // console.log('错误对象: ', error)

    console.error(message)
    if (message.includes('ResizeObserver loop completed with undelivered notifications')) {
      return
    }

    // 可以在这里进行错误日志记录、上报等操作
    const last_err = localStorage.getItem('last_send_err')
    if (message !== last_err) {
      localStorage.setItem('last_send_err', message)
      await send_catch_err(message, error)
    }

    // setTimeout(() => {
    //   location.reload()
    // }, 5000)
  }
}

function initVueCatchError(app) {
  app.config.errorHandler = async (err, instance, info) => {
    const timestamp = new Date().toLocaleString()

    console.group(
      `%c🚨 全局错误处理程序 [${timestamp}]`,
      'color: white; background-color: red; font-weight: bold; padding: 4px 6px; border-radius: 4px;'
    )
    console.log(info)
    console.log(err.stack)

    // 组件实例信息
    console.groupCollapsed('%c🔍\t组件信息', 'color: blue; font-weight: bold; font-size: 14px; cursor: pointer;')
    if (instance) {
      try {
        console.log('%c名称:', 'color: blue;', instance.type.name)
      } catch {
        console.log('%c名称:', 'color: blue;', '未知')
      }
      console.log('%c属性:', 'color: blue;', instance.props || {})
      console.dir(instance)
    } else {
      console.log('%c实例为 null 或 undefined', 'color: grey;')
    }
    console.groupEnd()

    console.groupEnd()

    const last_err = localStorage.getItem('last_send_err')
    if (err.stack !== last_err) {
      await send_catch_err(info, err.stack)
      localStorage.setItem('last_send_err', err.stack)
    }

    // setTimeout(() => {
    //   location.reload()
    // }, 5000)
  }
}

import './styles/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist' // pinia数据持久化
import i18n from './i18n' // 导入多语言配置
import App from './App.vue'
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons-vue'
import { config } from '@/config'
import menu from '@/ui/components/Menu/v-menu'
import { hasPermission, tryGetI18nText } from '@/utils'
import { useUserStore } from '@/stores/user'
import router from './router'
import { initCatchError } from '@/utils/catchError'
import { ElNotification } from 'element-plus'
import { getWebIco } from '@/utils/env'
import * as Sentry from '@sentry/vue'

// 初始化浏览器tab上的ico
const favicon = document.createElement('link')
favicon.rel = 'icon'
favicon.href = getWebIco()
document.head.appendChild(favicon)

// 初始化Vue
const app = createApp(App)
initCatchError(app)

const pinia = createPinia()
pinia.use(piniaPluginPersist)
app.use(pinia)
router.reloadRoutes(false)
app.use(i18n)
app.directive('menu', menu)
// app.use(ElementPlus)
// 注册所有 Element Plus 图标
for (const iconName in ElIconModules) {
  // console.log(iconName)
  config.elementPlusIconNames.push(iconName)
  app.component(iconName, ElIconModules[iconName])
}

// 注册指令: 元素是否可见, 需要开发者权限
app.directive('dev', {
  mounted(el) {
    if (!useUserStore().isDev) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
})

// 注册指令: 元素是否可见。值针对页面唯一
app.directive('permission', {
  mounted(el, binding) {
    // 检查权限
    const permissionValue = binding.value
    if (!hasPermission(permissionValue)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
})

// 无特殊作用，用于指定权限名
// 比如： v-permission-title="'xx权限'"
// 支持国际化：v-permission-title="'xxx.xxx'"
app.directive('permission-title', {})

// 注册指令：是否可以触发鼠标事件。值针对页面唯一
app.directive('permission-event', {
  mounted(el, binding) {
    if (!hasPermission(binding.value)) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((newNode) => {
            // console.log(newNode)
            if (newNode.nodeType === Node.ELEMENT_NODE) {
              lockElementEvent(newNode)
            }
          })
        })
      })

      observer.observe(el, { childList: true, subtree: true })
      lockElementEvent(el)
      // console.log('>>>>>>', el)
      el.querySelectorAll('*').forEach((child) => {
        // console.log(child)
        disableElement(child)
      })

      // 清理
      el._permissionEventObserver = observer
    }
  },
  unmounted(el) {
    // 清除事件监听器
    el.removeEventListener('click', preventClick, true)
    el.removeEventListener('contextmenu', preventClick, true)

    // 断开观察器
    if (el._permissionEventObserver) {
      el._permissionEventObserver.disconnect()
    }
  },
})

function lockElementEvent(el) {
  el.addEventListener('click', preventClick, true)
  el.addEventListener('contextmenu', preventClick, true)
  el.style.cursor = 'default' // 更改鼠标样式
}

// 注册指令：无权限时候置灰且无法触发点击事件。值针对页面唯一
app.directive('permission-enable', {
  beforeMount(el, binding) {
    if (!hasPermission(binding.value)) {
      disableElement(el)
      el.querySelectorAll('*').forEach((child) => {
        disableElement(child)
      })
    }
  },
  unmounted(el) {
    // 清除事件监听器
    el.removeEventListener('click', preventClick, true)
    el.removeEventListener('contextmenu', preventClick, true)
  },
})

function disableElement(element) {
  // 置灰样式
  element.style.opacity = '0.8'
  element.style.pointerEvents = 'none' // 禁止鼠标事件
  element.style.cursor = 'not-allowed' // 鼠标悬浮时的禁止图标
}

function preventClick(e) {
  e.stopPropagation()
  e.preventDefault()
}

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://6963dfec07952fcfe5ad7ead7a100255@sentry.link-api.com/9',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        instrumenter: (args) => {
          // 如果是 OPTIONS 请求，不添加追踪头部
          if (args.request?.method === 'OPTIONS') {
            return false
          }

          const url = args.request?.url
          if (!url) return false

          // 检查是否为同域名请求
          if (!url.startsWith(location.origin)) {
            return false
          }

          // 检查是否为静态资源
          if (
            /\.(js|mjs|jsx|ts|tsx|css|less|scss|sass|styl|png|jpg|jpeg|gif|svg|ico|woff|woff2|ttf|eot|otf|mp4|webm|ogg|mp3|wav|pdf|doc|docx|xls|xlsx|ppt|pptx|zip|rar|tar|gz|map)$/i.test(
              url
            )
          ) {
            return false
          }

          return true
        },
      }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    // 只追踪同域名请求
    tracePropagationTargets: [location.origin],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      // 过滤掉 Service Worker 相关的错误
      if (event.mechanism?.type === 'serviceworker') {
        return null
      }
      // 过滤掉包含 keepOnline 的错误和事件
      if (event.request?.url?.includes('keepOnline') || event.transaction?.includes('keepOnline')) {
        return null
      }
      return event
    },
  })
}

app.use(router)
app.mount('#app')
i18n.global.init()

/****************************************************************
 *              页面更新检测
 ****************************************************************/
console.log(import.meta.env.MODE)
console.log(navigator)
if (import.meta.env.PROD && 'serviceWorker' in navigator) {
  // 注册 Service Worker
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('[Service Worker] 注册成功，作用域是：', registration.scope)

        if (registration.installing) {
          console.log('[Service Worker] 正在安装.')
        } else if (registration.waiting) {
          console.log('[Service Worker] 已安装，等待激活')
        } else if (registration.active) {
          console.log('[Service Worker] 已经激活')
        }

        setInterval(() => {
          registration
            .update()
            .then(() => {
              console.log('[Service Worker] 检查更新成功.')
            })
            .catch((error) => {
              console.error('[Service Worker] 检查更新失败', error)
            })
        }, 10000) // 每10秒检查一次

        // 监听更新
        registration.onupdatefound = () => {
          const installingWorker = registration.installing
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              console.log('[Service Worker] installingWorker.state = ' + installingWorker.state)

              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  ElNotification({
                    title: tryGetI18nText('app.updateTipTitle'),
                    message: tryGetI18nText('app.updateTipContent'),
                    type: 'warning',
                    duration: 0,
                    showClose: false,
                    onClick: () => {
                      installingWorker.postMessage({ type: 'SKIP_WAITING' })
                      window.location.reload(true)
                    },
                  })

                  console.log('[Service Worker] 新内容可用，请刷新页面')
                } else {
                  console.log('[Service Worker] 内容已缓存，可离线使用')
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        console.error('[Service Worker] 注册失败, ', error)
      })
  })
} else {
  console.error('当前浏览器不支持 serviceWorker')
}

//
// const comps = import.meta.glob('/src/ui/**/*.*')
// console.error(comps)

/****************************************************************
 *              拦截用户按下 F5 键以刷新当前页面
 ****************************************************************/
// 添加全局的键盘事件监听器
// window.addEventListener('keydown', handleGlobalKeyDown)
//
// function handleGlobalKeyDown(event) {
//   // 拦截 F5 键
//   if (event.keyCode === 116) {
//     event.preventDefault() // 阻止默认行为
//     router.refreshCurPage()
//   }
// }
